import Axios from "axios";

import Cookies from "universal-cookie";

import {
  cookiesRegister,
  typeCookie,
  typeCookieEmail,
  typeCookiePage,
  typeCookiePageId,
  typeCookiePassw,
} from "fixedData";

import { SetListPag } from "interface/Page";
import { SetUserData } from "interface/Users";

export function Access(
  type,
  reg_email,
  reg_pass,
  CallbackSuccess,
  CallbackError
) {
  const cookies = new Cookies();
  const data = new FormData();

  data.append("type_post", "logar");
  data.append("type", type);
  data.append("email", reg_email);
  data.append("password", reg_pass);

  Axios({
    url: process.env.REACT_APP_PHP + "inc/login.php",
    mode: "no-cors",
    method: "POST",
    data: data,
  })
    .then((response) => {
      console.log(response.data);
      switch (response.data.type) {
        case "Connected":
          // current date
          const day = new Date();

          // add 3 day
          const add3Days = new Date();
          add3Days.setDate(day.getDate() + 2);

          CallbackSuccess();
          SetListPag("page", "dashboard");

          cookies.set(
            typeCookie,
            "dashboard",
            { path: "/", expires: add3Days },
            cookiesRegister
          );
          cookies.set(
            typeCookieEmail,
            response.data.email,
            { path: "/", expires: add3Days },
            cookiesRegister
          );
          cookies.set(
            typeCookiePassw,
            response.data.pass,
            { path: "/", expires: add3Days },
            cookiesRegister
          );

          // checked cookie page
          if (cookies.get(typeCookiePage)) {
            SetListPag("currentPage", cookies.get(typeCookiePage));
          } else {
            SetListPag("currentPage", "index");
            cookies.set(
              typeCookiePage,
              "index",
              { path: "/", expires: add3Days },
              cookiesRegister
            );
          }

          // checked cookie page id
          if (cookies.get(typeCookiePageId)) {
            SetListPag("currentPageId", cookies.get(typeCookiePageId));
          }

          SetUserData("id", response.data.id);
          SetUserData("idPage", response.data.idPage);
          SetUserData("file", response.data.file);
          SetUserData("name", response.data.name);
          SetUserData("email", response.data.email);
          SetUserData("nivel", response.data.nivel);
          SetUserData("page", response.data.page);
          break;

        default:
          CallbackError();
          SetListPag("page", "index");
          SetListPag("currentPage", "index");

          cookies.set(typeCookie, "index", "/", cookiesRegister);
          cookies.remove(typeCookieEmail, "", "/", cookiesRegister);
          cookies.remove(typeCookiePassw, "", "/", cookiesRegister);
          break;
      }
    })
    .catch((error) => {
      CallbackError();
      SetListPag("page", "index");
      SetListPag("currentPage", "index");

      cookies.set(typeCookiePage, "index", "/", cookiesRegister);
      cookies.remove(typeCookieEmail, "", "/", cookiesRegister);
      cookies.remove(typeCookiePassw, "", "/", cookiesRegister);
    });
}
