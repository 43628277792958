import { useState, useEffect } from "react";

import "./Details.css";

// import TextareaAutosize from "react-textarea-autosize";

import BtnSave from "components/BtnSave";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetListPag, RegisterListPag, UnRegisterListPag } from "interface/Page";
import {
  GetDataPage,
  RegisterDataPage,
  UnRegisterDataPage,
} from "interface/Data";
import {
  GetUserData,
  RegisterUserData,
  UnRegisterUserPage,
} from "interface/Users";

import { Svg_Delete, Svg_Img } from "components/Svg";

import { Reg_TechnicalAssistance } from "services/RegisterData";

export default function Page_Administrator_TechnicalAssistance_Details(props) {
  const [userId, setUserId] = useState(GetUserData("id"));
  const [idPage, setIdPage] = useState(GetListPag("currentPageId"));
  const [dataPage, setDataPage] = useState(GetDataPage("technical_assistance"));

  const [name, setName] = useState(InitialData("name"));
  const [state, setState] = useState(InitialData("state"));
  const [city, setCity] = useState(InitialData("city"));
  const [uf, setUf] = useState(InitialData("uf"));
  const [address, setAddress] = useState(InitialData("address"));
  const [complement, setComplement] = useState(InitialData("complement"));
  const [email, setEmail] = useState(InitialData("email"));
  const [responsable, setResponsable] = useState(InitialData("responsable"));
  const [phone, setPhone] = useState(InitialData("phone"));

  function InitialData(type) {
    if (idPage > 0) {
      const newData = GetDataPage("technical_assistance").find(
        (item) => item.id === idPage
      );
      if (newData) {
        return newData[type];
      }
      return "";
    }
    return "";
  }

  function SaveData(event) {
    event.preventDefault();
    props.setLoading(true);
    Reg_TechnicalAssistance(
      userId,
      idPage,
      name,
      state,
      city,
      uf,
      address,
      complement,
      email,
      responsable,
      phone,
      () => {
        CallbackSuccess();
      },
      () => {
        CallbackError();
      }
    );
  }

  function CallbackSuccess() {
    props.setLoading(false);
    SetModalData("ReturnResponse", { page: "ok", text: "" });
    SetModalState("ReturnResponse", true);
  }

  function CallbackError() {
    props.setLoading(false);
    SetModalData("ReturnResponse", { page: "erro", text: "" });
    SetModalState("ReturnResponse", true);
  }

  useEffect(() => {
    RegisterListPag("currentPageId", setIdPage);

    RegisterUserData("id", setUserId);
    RegisterDataPage("technical_assistance", setDataPage);

    return () => {
      UnRegisterListPag("currentPageId", setIdPage);

      UnRegisterUserPage("id", setUserId);
      UnRegisterDataPage("technical_assistance", setDataPage);
    };
  }, []);

  useEffect(() => {
    setIdPage(GetListPag("currentPageId"));
    setDataPage(GetDataPage("technical_assistance"));

    setName(InitialData("name"));
    setState(InitialData("state"));
    setCity(InitialData("city"));
    setUf(InitialData("uf"));
    setAddress(InitialData("address"));
    setComplement(InitialData("complement"));
    setEmail(InitialData("email"));
    setResponsable(InitialData("responsable"));
    setPhone(InitialData("phone"));
  }, [dataPage]);

  return (
    <form
      className="Page_Administrator_TechnicalAssistance_Details width_page"
      onSubmit={SaveData}
    >
      <BtnSave
        return={true}
        page="technical_assistance"
        ReturnPage={props.ReturnPage}
      />

      <div className="show_page_data">
        <div className="list_type_data">
          <div className="type_title">Dados da assistência técnica</div>
        </div>
        <div className="list_input_data">
          <div className="div_input space_div">
            <input
              type="text"
              className=""
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              maxLength={140}
              required
            />
            <span className="name_input">Nome*</span>
          </div>
          <div className="div_input">
            <input
              type="text"
              className=""
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
              maxLength={40}
              required
            />
            <span className="name_input">E-mail*</span>
          </div>
          <div className="div_input">
            <input
              type="text"
              className=""
              onChange={(e) => {
                setResponsable(e.target.value);
              }}
              value={responsable}
              maxLength={40}
              required
            />
            <span className="name_input">Responsável*</span>
          </div>
          <div className="div_input">
            <input
              type="text"
              className=""
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              value={phone}
              maxLength={20}
              required
            />
            <span className="name_input">Contato*</span>
          </div>
        </div>
        <div className="list_input_data">
          <div className="div_input space_div">
            <input
              type="text"
              className=""
              onChange={(e) => {
                setAddress(e.target.value);
              }}
              value={address}
              maxLength={140}
              required
            />
            <span className="name_input">Endereço*</span>
          </div>
        </div>
        <div className="list_input_data">
          <div className="div_input space_div">
            <input
              type="text"
              className=""
              onChange={(e) => {
                setState(e.target.value);
              }}
              value={state}
              maxLength={60}
              required
            />
            <span className="name_input">Estado*</span>
          </div>
          <div className="div_input">
            <input
              type="text"
              className="uf"
              onChange={(e) => {
                setUf(e.target.value);
              }}
              value={uf}
              maxLength={3}
              required
            />
            <span className="name_input">Sigla*</span>
          </div>
          <div className="div_input space_div">
            <input
              type="text"
              className=""
              onChange={(e) => {
                setCity(e.target.value);
              }}
              value={city}
              maxLength={60}
              required
            />
            <span className="name_input">Cidade*</span>
          </div>
        </div>
        <div className="list_input_data">
          <div className="div_input space_div">
            <input
              type="text"
              className=""
              onChange={(e) => {
                setComplement(e.target.value);
              }}
              value={complement}
              maxLength={140}
              required
            />
            <span className="name_input">Complemento*</span>
          </div>
        </div>
      </div>
    </form>
  );
}
