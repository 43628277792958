import { useState, useEffect } from "react";

import "./Details.css";

import JoditEditor from "jodit-react";
import { colorIcon, config } from "fixedData";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import BtnSave from "components/BtnSave";
import { Svg_Delete, Svg_Vimeo, Svg_Youtube } from "components/Svg";

import { GetUserData } from "interface/Users";
import { GetListPag, SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import {
  GetDataPage,
  RegisterDataPage,
  UnRegisterDataPage,
} from "interface/Data";

import { Reg_ArchitectsDetails } from "services/RegisterData";

export default function Page_Architect_Projects_Details(props) {
  const [idPage, setIdPage] = useState(GetListPag("currentPageId"));
  const [currentData, setCurrentData] = useState(GetDataPage("project"));

  const [cover, setCover] = useState(InitialData("cover"));
  const [statusCover, setStatusCover] = useState(false);
  const [title, setTitle] = useState(InitialData("title"));
  const [registerDate, setRegisterDate] = useState(
    InitialData("register_date")
  );
  const [text, setText] = useState(InitialData("text"));
  const [listGallery, setListGallery] = useState(InitialData("list_gallery"));

  function InitialData(type) {
    if (idPage !== 0) {
      const newData = GetDataPage("project").find((item) => item.id == idPage);
      return newData[type];
    }
    if (type == "list_gallery") {
      return [];
    }
    return "";
  }

  function AddFile(value) {
    if (value) {
      setCover(value);
      setStatusCover(true);
    } else {
      setCover("");
      setStatusCover(false);
    }
  }

  /* gallery */
  function HandleData(type, index, value) {
    const newData = [...listGallery];
    if (type === "file") {
      if (value) {
        newData[index]["file"] = value;
        newData[index]["status"] = true;
      } else {
        newData[index]["file"] = "";
        newData[index]["status"] = false;
      }
    } else {
      newData[index][type] = value;
    }
    setListGallery(newData);
  }
  function Delete(index, id, value) {
    if (id === 0) {
      const newData = [...listGallery];
      newData.splice(index, 1);
      setListGallery(newData);
    } else {
      SetModalData("Confirmation", {
        type: "delete_gallery",
        status: 0,
        id: id,
        origin: "architect",
        name: value,
      });
      SetModalState("Confirmation", true);
    }
  }
  /* end */

  function OpenFile(type, file) {
    SetModalData("ShowFile", { typeFile: "alone", type: type, file: file });
    SetModalState("ShowFile", true);
  }

  function SaveData(event) {
    event.preventDefault();
    props.setLoading(true);
    Reg_ArchitectsDetails(
      GetUserData("id"),
      idPage,
      cover,
      title,
      text,
      listGallery,
      () => {
        CallbackSuccess();
      },
      () => {
        CallbackError();
      }
    );
  }

  function CallbackSuccess() {
    props.setLoading(false);
    SetModalData("ReturnResponse", { page: "ok", text: "" });
    SetModalState("ReturnResponse", true);
  }

  function CallbackError() {
    props.setLoading(false);
    SetModalData("ReturnResponse", { page: "erro", text: "" });
    SetModalState("ReturnResponse", true);
  }

  function ReturnPage() {
    SetListPag("currentPage", "architects");
    SetListPag("currentPageId", "remuve");
  }

  useEffect(() => {
    RegisterDataPage("project", setCurrentData);
    return () => {
      UnRegisterDataPage("project", setCurrentData);
    };
  }, []);

  useEffect(() => {
    setCurrentData(GetDataPage("project"));
  }, [currentData]);

  return (
    <form
      className="Page_Architect_Projects_Details width_page"
      onSubmit={SaveData}
    >
      <BtnSave return={true} page="architects" ReturnPage={props.ReturnPage} />

      {idPage === 0 ? null : (
        <div className="no_data">Registrado na data: {registerDate}</div>
      )}

      <div className="show_page_data">
        <div className="list_type_data">
          <div className="type_title">Dados do projeto</div>
        </div>
        <div className="list_input_data">
          <div className="div_input space_div">
            <input
              type="text"
              className=""
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              value={title}
              maxLength={140}
            />
            <span className="name_input">Título</span>
          </div>
          <span className="div_input div_add_img">
            <div
              className={
                statusCover == true
                  ? "new_file_add new_file_add_active"
                  : "new_file_add"
              }
            >
              <label>
                {statusCover == true ? "Capa adicionada" : "Adicionar capa"}
                <input
                  type="file"
                  onChange={(e) => {
                    AddFile(e.target.files[0]);
                  }}
                  accept="image/*"
                />
              </label>
            </div>
            {cover !== "" ? (
              <>
                <div
                  className="delete_img_add"
                  onClick={() => {
                    AddFile("");
                  }}
                >
                  <Svg_Delete className="icons" color="#ffffff" />
                </div>
                <div
                  className="show_img_ cursor_pointer"
                  onClick={() => {
                    OpenFile(
                      "showImg",
                      statusCover ? URL.createObjectURL(cover) : cover
                    );
                  }}
                  data-tooltip-id="show_cover"
                  data-tooltip-content="Clique para ampliar a imagem"
                  data-tooltip-place="top"
                >
                  <img
                    alt="Cover"
                    src={statusCover ? URL.createObjectURL(cover) : cover}
                    className="icons_cover"
                  />
                </div>
                <Tooltip id="show_cover" />
              </>
            ) : null}
          </span>
        </div>
        <div className="list_input_data">
          <div className="div_input space_div">
            <JoditEditor
              config={config}
              value={text ? text.replaceAll("&#34;", '"') : ""}
              onBlur={(newContent) => setText(newContent)}
            />
            <span className="name_input">Descrição</span>
          </div>
        </div>
      </div>

      <div className="show_page_data">
        <div className="list_type_data">
          <div className="type_title">Galeria</div>
          <div
            className="btn_save"
            onClick={() => {
              setListGallery([
                ...listGallery,
                {
                  id: 0,
                  type: "img",
                  name: "",
                  file: "",
                  status: false,
                  link: "",
                },
              ]);
            }}
          >
            Adicionar nova galeria
          </div>
        </div>
        {listGallery.length > 0 ? (
          listGallery.map((elem, index) => {
            return (
              <div className="list_input_data div_line_top" key={index}>
                <div className="div_input">
                  <input
                    type="text"
                    className=""
                    onChange={(e) => {
                      HandleData(
                        "name",
                        index,
                        e.target.value.replaceAll(" ", "")
                      );
                    }}
                    value={elem.name}
                    maxLength={140}
                  />
                  <span className="name_input">Nome</span>
                </div>
                <div className="div_input">
                  <select
                    onChange={(e) => {
                      HandleData("type", index, e.target.value);
                    }}
                    value={elem.type}
                    required
                  >
                    <option value="img">Imagem</option>
                    <option value="youtube">Vídeo youtube</option>
                    <option value="vimeo">Vídeo vimeo</option>
                  </select>
                  <span className="name_input">Tipo*</span>
                </div>
                {elem.type == "img" ? (
                  <span className="div_input div_add_img">
                    <div
                      className={
                        elem.status === true
                          ? "new_file_add new_file_add_active"
                          : "new_file_add"
                      }
                    >
                      <label>
                        {elem.status === true
                          ? "Imagem adicionada"
                          : "Adicionar imagem"}
                        <input
                          type="file"
                          onChange={(e) => {
                            HandleData("file", index, e.target.files[0]);
                          }}
                          accept="image/*"
                        />
                      </label>
                    </div>
                    {elem.file !== "" ? (
                      <>
                        <div
                          className="delete_img_add"
                          onClick={() => {
                            HandleData("file", index, "");
                          }}
                        >
                          <Svg_Delete className="icons" color="#ffffff" />
                        </div>
                        <div
                          className="show_img_ cursor_pointer"
                          onClick={() => {
                            OpenFile(
                              "showImg",
                              elem.status
                                ? URL.createObjectURL(elem.file)
                                : elem.file
                            );
                          }}
                          data-tooltip-id={"show_img_" + elem.id}
                          data-tooltip-content="Clique para ampliar a imagem"
                          data-tooltip-place="top"
                        >
                          <img
                            alt="img gallery"
                            src={
                              elem.status
                                ? URL.createObjectURL(elem.file)
                                : elem.file
                            }
                            className="icons"
                          />
                        </div>
                        <Tooltip id={"show_img_" + elem.id} />
                      </>
                    ) : null}
                  </span>
                ) : (
                  <>
                    <div className="div_input space_div">
                      <input
                        type="text"
                        className=""
                        onChange={(e) => {
                          HandleData(
                            "link",
                            index,
                            e.target.value.replaceAll(" ", "")
                          );
                        }}
                        value={elem.link}
                      />
                      <span className="name_input">Link do vídeo</span>
                    </div>
                    {elem.link !== "" ? (
                      <div
                        className="div_input"
                        onClick={() => {
                          OpenFile(elem.type, elem.link);
                        }}
                      >
                        {elem.type === "vimeo" ? (
                          <Svg_Vimeo className="icons" color={colorIcon} />
                        ) : (
                          <Svg_Youtube className="icons" color={colorIcon} />
                        )}
                      </div>
                    ) : null}
                  </>
                )}
                <div
                  className="div_input"
                  onClick={() => {
                    Delete(index, elem.id, elem.name);
                  }}
                >
                  <Svg_Delete className="icons" color="#f00000" />
                </div>
              </div>
            );
          })
        ) : (
          <div className="no_data">Nenhum arquivo encontrado...</div>
        )}
      </div>
    </form>
  );
}
