import { useState, useEffect } from "react";

import "./Details.css";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import TextareaAutosize from "react-textarea-autosize";

import BtnSave from "components/BtnSave";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetListPag, RegisterListPag, UnRegisterListPag } from "interface/Page";
import {
  GetDataPage,
  RegisterDataPage,
  UnRegisterDataPage,
} from "interface/Data";
import {
  GetUserData,
  RegisterUserData,
  UnRegisterUserPage,
} from "interface/Users";

import {
  Svg_Add,
  Svg_Delete,
  Svg_Img,
  Svg_Vimeo,
  Svg_Youtube,
} from "components/Svg";

import { colorIcon } from "fixedData";

import { Reg_Product } from "services/RegisterData";

export default function Page_Administrator_ProductCatalog_Details(props) {
  const [userId, setUserId] = useState(GetUserData("id"));
  const [idPage, setIdPage] = useState(GetListPag("currentPageId"));
  const [dataPage, setDataPage] = useState(GetDataPage("product"));

  const [templateStatus, setTemplateStatus] = useState(false);
  const [templateSelect, setTemplateSelect] = useState("");
  const [listTemplate, setListTemplate] = useState(
    GetDataPage("product_template")
  );

  const [showListCategory, setShowListCategory] = useState(
    ListData("category")
  );
  const [showListItens, setShowListItens] = useState(ListData("itens"));

  const [codeSku, setCodeSku] = useState(InitialData("code_sku"));
  const [id3d, setId3d] = useState(InitialData("id_3d"));
  const [title, setTitle] = useState(InitialData("title"));
  const [inmetro, setInmetro] = useState(InitialData("inmetro"));
  const [highlightTitle, setHighlightTitle] = useState(
    InitialData("highlight_title")
  );
  const [file, setFile] = useState(InitialData("file"));
  const [fileStatus, setFileStatus] = useState(false);
  const [subtitle, setSubtitle] = useState(InitialData("subtitle"));
  const [typeVideo, setTypeVideo] = useState(InitialData("type_video"));
  const [linkVideo, setLinkVideo] = useState(InitialData("link_video"));
  const [whereBuy, setWhereBuy] = useState(InitialData("where_buy"));
  const [filesOfProject, setFilesOfProject] = useState(
    InitialData("filesOfProject")
  );
  const [manual, setManual] = useState(InitialData("manual"));
  const [descTitle, setDescTitle] = useState(InitialData("desc_title"));
  const [description, setDescription] = useState(InitialData("description"));

  const [typeGallery, setTypeGallery] = useState(InitialData("type_gallery"));
  const [listImg, setListImg] = useState(InitialData("list_img"));
  const [listCategory, setListCategory] = useState(
    InitialData("list_category")
  );
  const [listComponents, setListComponents] = useState(
    InitialData("list_components")
  );

  function InitialData(type) {
    if (idPage > 0) {
      const newData = GetDataPage("product").find((item) => item.id == idPage);
      if (newData) {
        return newData[type];
      }
      if (
        type === "list_img" ||
        type === "list_category" ||
        type === "list_components"
      ) {
        return [];
      }
      if (type === "inmetro" || type === "highlight_title") {
        return false;
      }
      if (type === "type_gallery") {
        return "UniqueImageContainer";
      }
      return "";
    }
    if (
      type === "list_img" ||
      type === "list_category" ||
      type === "list_components"
    ) {
      return [];
    }
    if (type === "inmetro" || type === "highlight_title") {
      return false;
    }
    if (type === "type_gallery") {
      return "UniqueImageContainer";
    }
    return "";
  }

  function UseTemplate(value) {
    const newData = GetDataPage("product_template").find(
      (item) => item.id == value
    );

    if (newData) {
      newData["components"].map((elem, index) => {
        elem.id = 0;
        elem.itens.map((elem_1, index_1) => {
          elem_1.id = 0;
          elem_1.list.map((elem_2, index_2) => {
            elem_2.id = 0;
          });
        });
      });

      setTypeGallery(newData["type_gallery"]);
      setHighlightTitle(newData["highlight_title"]);
      const newDataNav = newData["components"].find(
        (item) => item.type == "nav"
      );
      if (newDataNav) {
        const newDataImg = [];
        newDataNav["itens"].map((elem, index) => {
          elem.list.map((elem_1, index_1) => {
            newDataImg.push({ id: 0, name: elem_1.name, list: [] });
          });
        });
        setListImg(newDataImg);
      }

      setListComponents(newData["components"]);
      setTemplateStatus(value);
    }
  }

  function ShowDataImg() {
    return (
      <div className="show_page_data">
        <div className="list_type_data">
          <div className="type_title">Galeria de imagens</div>
          <div
            className="btn_save"
            onClick={() => {
              setListImg([...listImg, { id: 0, name: "", list: [] }]);
            }}
          >
            Adicionar novo bloco de imagem
          </div>
        </div>
        {listImg.length > 0 ? (
          <>
            <div className="list_input_data border_bottom space_div">
              <div className="title_block">Tipo de galeria</div>

              <div className="div_input checkbox">
                <input
                  type="radio"
                  name="type_gallery"
                  value={true}
                  onChange={() => {
                    setTypeGallery("UniqueImageContainer");
                  }}
                  checked={typeGallery == "UniqueImageContainer" ? true : false}
                />
                <span className="name_checkbox">Imagem única</span>
              </div>

              <div className="div_input checkbox">
                <input
                  type="radio"
                  name="type_gallery"
                  value={true}
                  onChange={() => {
                    setTypeGallery("SelectImageContainer");
                  }}
                  checked={typeGallery == "SelectImageContainer" ? true : false}
                />
                <span className="name_checkbox">Galeria de imagem</span>
              </div>
            </div>
            {listImg.map((elem, index) => {
              return (
                <div
                  className="list_input_data flex_direction_column div_box_img"
                  key={index}
                >
                  <div className="list_input_data space_top_not space_div">
                    <div className="div_input space_div">
                      <input
                        type="text"
                        className=""
                        onChange={(e) => {
                          HandleDataImg("name", index, e.target.value);
                        }}
                        value={elem.name}
                        maxLength={140}
                      />
                      <span className="name_input">Nome da galeria</span>
                    </div>
                    <div
                      className="div_input"
                      onClick={() => {
                        DeleteImg(index, elem.id, elem.name);
                      }}
                    >
                      <Svg_Delete className="icons" color="#f00000" />
                    </div>
                  </div>

                  <div className="list_input_data flex_direction_row div_show_list_img">
                    {elem.list.map((elem_1, index_1) => {
                      return (
                        <div className="div_input" key={index_1}>
                          <div className="show_img add_img">
                            <div
                              className="delete"
                              data-tooltip-id={
                                "delete_" + elem.id + "_" + index
                              }
                              data-tooltip-content="Deletar foto"
                              data-tooltip-place="top"
                              onClick={() => {
                                DeleteImgList(
                                  index,
                                  index_1,
                                  elem_1.id,
                                  elem_1.name
                                );
                              }}
                            >
                              <Svg_Delete className="icons" color="#f00000" />
                            </div>
                            <Tooltip id={"delete_" + elem.id + "_" + index} />
                            {elem_1.status ? (
                              <div
                                className="img_"
                                style={{
                                  backgroundImage:
                                    'url("' +
                                    URL.createObjectURL(elem_1.file) +
                                    '")',
                                }}
                                onClick={() => {
                                  OpenFile(
                                    "showImg",
                                    URL.createObjectURL(elem_1.file)
                                  );
                                }}
                              />
                            ) : (
                              <div
                                className="img_"
                                style={{
                                  backgroundImage: 'url("' + elem_1.file + '")',
                                }}
                                onClick={() => {
                                  OpenFile("showImg", elem_1.file);
                                }}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                    <div className="div_input">
                      <label>
                        <input
                          type="file"
                          onChange={(e) => {
                            HandleDataImg("list", index, e.target.files[0]);
                          }}
                          accept="image/*"
                        />
                        <div
                          className="show_img add_img"
                          data-tooltip-id="add_file_img"
                          data-tooltip-content="Adicionar nova foto"
                          data-tooltip-place="top"
                        >
                          <Svg_Add className="icons" color="#B4B4B4" />
                        </div>
                        <Tooltip id="add_file_img" />
                      </label>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div className="no_data">Nenhuma imagem encontrada...</div>
        )}
      </div>
    );
  }

  function ShowDataComponents() {
    return (
      <div className="show_page_data">
        <div className="list_type_data">
          <div className="type_title">Lista de especificações do produto</div>
          <div
            className="btn_save"
            onClick={() => {
              setListComponents([
                ...listComponents,
                {
                  id: 0,
                  name: "",
                  type: "",
                  size: "",
                  separator_: "",
                  image: "",
                  image_status: false,
                  nameNav: "",
                  img: [],
                  itens: [],
                },
              ]);
            }}
          >
            Adicionar nova especificação
          </div>
        </div>
        {listComponents.length > 0 ? (
          listComponents.map((elem, index) => {
            return (
              <div className="div_bloco_img" key={index}>
                <div className="list_input_data">
                  <div className="div_input space_div">
                    <input
                      type="text"
                      className=""
                      onChange={(e) => {
                        HandleDataComponents("name", index, e.target.value);
                      }}
                      value={elem.name}
                      maxLength={140}
                      required
                    />
                    <span className="name_input">Nome</span>
                  </div>
                  <div className="div_input">
                    <select
                      onChange={(e) => {
                        HandleDataComponents("type", index, e.target.value);
                      }}
                      value={elem.type}
                    >
                      <option value="">Selecione uma opção</option>
                      <option value="list">Lista</option>
                      <option value="links">links</option>
                      {listComponents.find((item) => item.type == "nav") ? (
                        <option value="nav" hidden>
                          Navegação
                        </option>
                      ) : (
                        <option value="nav">Navegação</option>
                      )}
                      <option value="specifications">Especificação</option>
                      <option value="specificationImage">
                        Especificação com imagem
                      </option>
                    </select>
                    <span className="name_input">Tipo</span>
                  </div>
                  <div className="div_input">
                    <select
                      onChange={(e) => {
                        HandleDataComponents("size", index, e.target.value);
                      }}
                      value={elem.size}
                    >
                      <option value="medium">Médio</option>
                      <option value="large">Grande</option>
                    </select>
                    <span className="name_input">Tamanho</span>
                  </div>
                  {elem.type === "nav" ? null : (
                    <div className="div_input space_div">
                      <select
                        onChange={(e) => {
                          HandleDataComponents(
                            "nameNav",
                            index,
                            e.target.value
                          );
                        }}
                        value={elem.nameNav}
                      >
                        <option value="">Selecione uma opção</option>
                        {elem.type != "nav"
                          ? listComponents.map((elem_1, index_1) => {
                              if (elem_1.name != "") {
                                if (elem_1.name != elem.name) {
                                  return (
                                    <option value={elem_1.name} key={index_1}>
                                      {elem_1.name}
                                    </option>
                                  );
                                }
                              }
                            })
                          : null}
                      </select>
                      <span className="name_input">Nome da navegação</span>
                    </div>
                  )}
                  {elem.type === "specificationImage" ? (
                    <span className="div_input div_add_img">
                      <div
                        className={
                          elem.image
                            ? "new_file_add new_file_add_active"
                            : "new_file_add"
                        }
                      >
                        <label>
                          {elem.image_status
                            ? "Foto adicionada"
                            : "Adicionar foto"}
                          <input
                            type="file"
                            onChange={(e) => {
                              HandleDataComponents(
                                "image",
                                index,
                                e.target.files[0]
                              );
                            }}
                            accept="image/*"
                          />
                        </label>
                      </div>
                      {elem.image != "" ? (
                        <>
                          <div
                            className="delete_img_add"
                            onClick={() => {
                              HandleDataComponents("image", index, "");
                            }}
                          >
                            <Svg_Delete className="icons" color="#ffffff" />
                          </div>
                          <div
                            className="show_img_"
                            onClick={() => {
                              OpenFile(
                                "showImg",
                                elem.image_status
                                  ? URL.createObjectURL(elem.image)
                                  : elem.image
                              );
                            }}
                          >
                            <Svg_Img color="#324d6b" className="icons" />
                          </div>
                        </>
                      ) : null}
                    </span>
                  ) : (
                    <div className="div_input">
                      <input
                        type="text"
                        className="separator"
                        onChange={(e) => {
                          HandleDataComponents(
                            "separator_",
                            index,
                            e.target.value
                          );
                        }}
                        value={elem.separator_}
                        maxLength={4}
                      />
                      <span className="name_input">Separador</span>
                    </div>
                  )}
                  <div
                    className="div_input"
                    onClick={() => {
                      DeleteComponents(elem.id, index);
                    }}
                  >
                    <Svg_Delete className="icons" color="#f00000" />
                  </div>
                </div>

                <div className="list_input_data div_data_input flex_direction_column">
                  <div
                    className="list_type_data space_top space_div"
                    style={{ marginBottom: 0 }}
                  >
                    <div className="type_title">Lista de itens</div>
                    <div className="div_btn">
                      <div
                        className="btn_save"
                        onClick={() => {
                          HandleDataComponentsAdd("itens", index);
                        }}
                      >
                        Adicionar nova lista
                      </div>
                    </div>
                  </div>
                  {elem.itens.map((elem_1, index_1) => {
                    return (
                      <div
                        className="list_input_data div_line_top"
                        key={index_1}
                      >
                        <div className="list_input_data space_top_not">
                          <div className="div_input space_div">
                            <input
                              type="text"
                              className=""
                              onChange={(e) => {
                                HandleDataComponentsItens(
                                  "itens",
                                  "name",
                                  index,
                                  index_1,
                                  e.target.value
                                );
                              }}
                              value={elem_1.name}
                              maxLength={140}
                            />
                            <span className="name_input">Nome da lista</span>
                          </div>
                          <div
                            className="div_input btn_save"
                            onClick={() => {
                              HandleDataComponentsItensAdd(
                                "itens",
                                index,
                                index_1
                              );
                            }}
                          >
                            Adicionar item
                          </div>
                          <div
                            className="div_input"
                            onClick={() => {
                              DeleteComponentsItens(
                                "itens",
                                index,
                                index_1,
                                elem_1.id,
                                "item"
                              );
                            }}
                          >
                            <Svg_Delete className="icons" color="#f00000" />
                          </div>
                        </div>
                        {elem_1.list.length > 0 ? (
                          <div className="list_input_data div_data_input show_data_line flex_direction_column">
                            {elem_1.list.map((elem_2, index_2) => {
                              return (
                                <div className="data_line" key={index_2}>
                                  <div className="div_input">
                                    <select
                                      onChange={(e) => {
                                        HandleDataComponentsList(
                                          "itens",
                                          "id_itens",
                                          index,
                                          index_1,
                                          index_2,
                                          e.target.value
                                        );
                                      }}
                                      value={elem_2.id_itens}
                                      required
                                    >
                                      <option value="">
                                        Selecione uma opção
                                      </option>
                                      <option value="addNewData">
                                        Adicionar novo nome
                                      </option>
                                      {showListItens.map((elem_3, index_3) => {
                                        return (
                                          <option
                                            value={elem_3.id}
                                            key={index_3}
                                          >
                                            {elem_3.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <span className="name_input">Nome</span>
                                  </div>
                                  {elem_2.id_itens === "addNewData" ? (
                                    <div className="div_input space_div">
                                      <input
                                        type="text"
                                        className=""
                                        onChange={(e) => {
                                          HandleDataComponentsList(
                                            "itens",
                                            "newItens",
                                            index,
                                            index_1,
                                            index_2,
                                            e.target.value
                                          );
                                        }}
                                        value={elem_2.newItens}
                                        maxLength={40}
                                      />
                                      <span className="name_input">
                                        Novo nome
                                      </span>
                                    </div>
                                  ) : null}

                                  {elem.type === "nav" ? (
                                    <>
                                      <div className="div_input">
                                        <input
                                          type="text"
                                          className="div_input_code"
                                          onChange={(e) => {
                                            HandleDataComponentsList(
                                              "itens",
                                              "code_sku",
                                              index,
                                              index_1,
                                              index_2,
                                              e.target.value
                                            );
                                          }}
                                          value={elem_2.code_sku}
                                          maxLength={12}
                                          required
                                        />
                                        <span className="name_input">
                                          Código Sku
                                        </span>
                                      </div>
                                      <div
                                        className="div_input div_code"
                                        onClick={() => {
                                          GeneratorCodeList(
                                            index,
                                            index_1,
                                            index_2
                                          );
                                        }}
                                      >
                                        Gerar código aleátorio
                                      </div>
                                    </>
                                  ) : null}

                                  <div
                                    className="div_input"
                                    onClick={() => {
                                      DeleteComponentsList(
                                        "itens",
                                        index,
                                        index_1,
                                        index_2,
                                        elem_2.id,
                                        "item"
                                      );
                                    }}
                                    style={{ position: "relative", top: "8px" }}
                                  >
                                    <Svg_Delete
                                      className="icons"
                                      color="#f00000"
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
        ) : (
          <div className="no_data">Nenhuma especificação encontrada...</div>
        )}
      </div>
    );
  }

  function ListData(type) {
    const newData = GetDataPage("list_data").filter(
      (item) => item.type == type
    );
    if (newData) {
      return newData;
    }
    return [];
  }

  function AddFile(setFile, setStatus, value) {
    if (value) {
      setFile(value);
      setStatus(true);
    } else {
      setFile("");
      setStatus(false);
    }
  }

  function OpenFile(type, file) {
    SetModalData("ShowFile", { type: type, file: file });
    SetModalState("ShowFile", true);
  }

  /* category */
  function HandleDataCategory(type, index, value) {
    const newData = [...listCategory];
    newData[index][type] = value;
    setListCategory(newData);
  }
  function DeleteCategory(index, id, value) {
    if (id == 0) {
      const newData = [...listCategory];
      newData.splice(index, 1);
      setListCategory(newData);
    } else {
      SetModalData("Confirmation", {
        type: "delete_category",
        status: 0,
        id: id,
        origin: "product",
        name: value,
      });
      SetModalState("Confirmation", true);
    }
  }
  /* end */

  /* listImg */
  function HandleDataImg(type, index, value) {
    const newData = [...listImg];
    if (type === "list") {
      newData[index]["list"].push({
        id: 0,
        name: "",
        file: value,
        status: true,
      });
    } else {
      newData[index][type] = value;
    }
    setListImg(newData);
  }
  function DeleteImg(index, id, value) {
    if (id == 0) {
      const newData = [...listImg];
      newData.splice(index, 1);
      setListImg(newData);
    } else {
      SetModalData("Confirmation", {
        type: "delete_list_img",
        status: 0,
        id: id,
        origin: "product",
        name: value,
      });
      SetModalState("Confirmation", true);
    }
  }

  function HandleDataImgList(type, index, index_1, value) {
    const newData = [...listImg];
    if (type === "file") {
      if (value) {
        newData[index]["list"][index_1]["file"] = value;
        newData[index]["list"][index_1]["status"] = true;
      } else {
        newData[index]["list"][index_1]["file"] = "";
        newData[index]["list"][index_1]["status"] = false;
      }
    } else {
      newData[index]["list"][index_1][type] = value;
    }
    setListImg(newData);
  }
  function DeleteImgList(index, index_1, id, value) {
    if (id == 0) {
      const newData = [...listImg];
      newData[index]["list"].splice(index_1, 1);
      setListImg(newData);
    } else {
      SetModalData("Confirmation", {
        type: "delete_img",
        status: 0,
        id: id,
        origin: "product",
        name: value,
      });
      SetModalState("Confirmation", true);
    }
  }
  /*/

    /* components */
  function HandleDataComponents(type, index, value) {
    const newData = [...listComponents];
    if (type === "nameNav") {
      newData.map((elem, index_2) => {
        if (elem.type === "nav") {
          elem.itens.map((elem_1, index_3) => {
            elem_1.list.map((elem_2, index_4) => {
              newData[index]["itens"].push({
                id: 0,
                name: elem_2.name,
                list: [],
              });
            });
          });
        }
      });
      newData[index][type] = value;
    } else {
      if (type === "image") {
        if (value) {
          newData[index]["image"] = value;
          newData[index]["image_status"] = true;
        } else {
          newData[index]["image"] = "";
          newData[index]["image_status"] = false;
        }
      } else {
        newData[index][type] = value;
      }
    }
    setListComponents(newData);
  }
  function DeleteComponents(id, index) {
    if (id == 0) {
      const newData = [...listComponents];
      newData.splice(index, 1);
      setListComponents(newData);
    } else {
      SetModalData("Confirmation", {
        type: "delete_all_list",
        status: 0,
        id: id,
        origin: "product",
        name: "Lista de itens",
      });
      SetModalState("Confirmation", true);
    }
  }

  function HandleDataComponentsAdd(origin, index) {
    const newData = [...listComponents];
    if (origin === "itens") {
      newData[index]["itens"].push({
        id: 0,
        name: "",
        list: [{ id: 0, id_category: 0, newCategory: "" }],
      });
    } else if (origin === "img") {
      newData[index]["img"].push({
        id: 0,
        type_list: "",
        name: "",
        list: [{ id: 0, name: "", file: "", status: false }],
      });
    }
    setListComponents(newData);
  }
  function HandleDataComponentsItens(origin, type, index, index_1, value) {
    const newData = [...listComponents];
    newData[index][origin][index_1][type] = value;
    setListComponents(newData);
  }
  function HandleDataComponentsItensAdd(origin, index, index_1) {
    const newData = [...listComponents];
    if (origin === "itens") {
      newData[index][origin][index_1]["list"].push({
        id: 0,
        id_itens: 0,
        newItens: "",
        code_sku: "",
      });
    } else if (origin === "img") {
      newData[index][origin][index_1]["list"].push({
        id: 0,
        name: "",
        file: "",
        status: false,
      });
    }
    setListComponents(newData);
  }
  function DeleteComponentsItens(origin, index, index_1, id, value) {
    if (id == 0) {
      const newData = [...listComponents];
      newData[index][origin].splice(index_1, 1);
      setListComponents(newData);
    } else {
      SetModalData("Confirmation", {
        type: "delete_list_" + origin,
        status: 0,
        id: id,
        origin: "product",
        name: value,
      });
      SetModalState("Confirmation", true);
    }
  }

  function GeneratorCodeList(index, index_1, index_2) {
    const newData = [...listComponents];
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < 12; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    newData[index]["itens"][index_1]["list"][index_2]["code_sku"] = result;
    setListComponents(newData);
  }
  function HandleDataComponentsList(
    origin,
    type,
    index,
    index_1,
    index_2,
    value
  ) {
    const newData = [...listComponents];
    if (origin === "itens") {
      newData[index]["itens"][index_1]["list"][index_2][type] = value;
    } else if (origin === "img") {
      if (type === "file") {
        if (value) {
          newData[index]["img"][index_1]["list"][index_2]["file"] = value;
          newData[index]["img"][index_1]["list"][index_2]["status"] = true;
        } else {
          newData[index]["img"][index_1]["list"][index_2]["file"] = "";
          newData[index]["img"][index_1]["list"][index_2]["status"] = false;
        }
      }
      newData[index]["img"][index_1]["list"][index_2][type] = value;
    }

    setListComponents(newData);
  }
  function DeleteComponentsList(origin, index, index_1, index_2, id, value) {
    if (id == 0) {
      const newData = [...listComponents];
      newData[index][origin][index_1]["list"].splice(index_2, 1);
      setListComponents(newData);
    } else {
      SetModalData("Confirmation", {
        type: "delete_" + origin,
        status: 0,
        id: id,
        origin: "product",
        name: value,
      });
      SetModalState("Confirmation", true);
    }
  }
  /* end */

  function SaveData(event) {
    event.preventDefault();
    props.setLoading(true);

    Reg_Product(
      userId,
      idPage,
      codeSku,
      id3d,
      title,
      inmetro,
      highlightTitle,
      file,
      subtitle,
      typeVideo,
      linkVideo,
      whereBuy,
      descTitle,
      description,
      typeGallery,
      listImg,
      listCategory,
      listComponents,
      filesOfProject,
      manual,
      () => {
        CallbackSuccess();
      },
      () => {
        CallbackError();
      }
    );
  }

  function GenerateCodeSku(setValue, length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setValue(result);
  }

  function ShowSkuAll() {
    if (listComponents.find((item) => item.type == "nav")) {
      return null;
    } else {
      return (
        <>
          <div className="div_input">
            <input
              type="text"
              className="div_input_code"
              onChange={(e) => {
                setCodeSku(e.target.value);
              }}
              value={codeSku}
              maxLength={12}
              required
            />
            <span className="name_input">Código Sku</span>
          </div>
          <div
            className="div_input div_code"
            onClick={() => {
              GenerateCodeSku(setCodeSku, 12);
            }}
          >
            Gerar código aleátorio
          </div>
        </>
      );
    }
  }

  function CallbackSuccess() {
    props.setLoading(false);
    SetModalData("ReturnResponse", { page: "ok", text: "" });
    SetModalState("ReturnResponse", true);
  }

  function CallbackError() {
    props.setLoading(false);
    SetModalData("ReturnResponse", { page: "erro", text: "" });
    SetModalState("ReturnResponse", true);
  }

  useEffect(() => {
    RegisterUserData("id", setUserId);
    RegisterDataPage("product", setDataPage);
    RegisterListPag("currentPageId", setIdPage);

    return () => {
      UnRegisterUserPage("id", setUserId);
      UnRegisterDataPage("product", setDataPage);
      UnRegisterListPag("currentPageId", setIdPage);
    };
  }, []);

  useEffect(() => {
    setIdPage(GetListPag("currentPageId"));
    setListTemplate(GetDataPage("product_template"));

    setShowListCategory(ListData("category"));
    setShowListItens(ListData("itens"));

    setCodeSku(InitialData("code_sku"));
    setId3d(InitialData("id_3d"));
    setTitle(InitialData("title"));
    setInmetro(InitialData("inmetro"));
    setHighlightTitle(InitialData("highlight_title"));
    setFile(InitialData("file"));
    setFileStatus(false);
    setSubtitle(InitialData("subtitle"));
    setTypeVideo(InitialData("type_video"));
    setLinkVideo(InitialData("link_video"));
    setWhereBuy(InitialData("where_buy"));
    setDescTitle(InitialData("desc_title"));
    setDescription(InitialData("description"));

    setTypeGallery(InitialData("type_gallery"));
    setListImg(InitialData("list_img"));
    setListCategory(InitialData("list_category"));
    setListComponents(InitialData("list_components"));
  }, [dataPage]);

  useEffect(() => {
    ShowSkuAll();
  }, [listComponents]);

  return (
    <form
      className="Page_Administrator_ProductCatalog_Details width_page"
      onSubmit={SaveData}
    >
      <BtnSave return={true} page="product" ReturnPage={props.ReturnPage} />

      {/* Dados do produto */}
      <div className="show_page_data">
        <div className="list_type_data">
          <div className="type_title">Dados do produto</div>
        </div>
        <div className="list_input_data">
          {ShowSkuAll()}

          <div className="div_input space_div">
            <input
              type="text"
              className=""
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              value={title}
              maxLength={140}
              required
            />
            <span className="name_input">Título*</span>
          </div>
          <div className="div_input checkbox">
            <input
              type="checkbox"
              value={true}
              onChange={() => {
                setInmetro(!inmetro);
              }}
              checked={inmetro}
            />
            <span className="name_checkbox">Mostrar inmetro</span>
          </div>
          <div className="div_input checkbox">
            <input
              type="checkbox"
              value={true}
              onChange={() => {
                setHighlightTitle(!highlightTitle);
              }}
              checked={highlightTitle}
            />
            <span className="name_checkbox">Destacar título</span>
          </div>
        </div>
        <div className="list_input_data">
          <div className="div_input space_div">
            <input
              type="text"
              onChange={(e) => {
                setSubtitle(e.target.value);
              }}
              value={subtitle}
              maxLength={140}
            />
            <span className="name_input">Subtitle</span>
          </div>
          <div className="div_input">
            <input
              type="text"
              className="div_input_3d"
              onChange={(e) => {
                setId3d(e.target.value);
              }}
              value={id3d}
              maxLength={80}
            />
            <span className="name_input">ID 3D</span>
          </div>
        </div>
        <div className="list_input_data">
          <div className="div_input">
            <select
              onChange={(e) => {
                setTypeVideo(e.target.value);
              }}
              value={typeVideo}
            >
              <option value="">Selecione uma opção</option>
              <option value="vimeo">Vimeo</option>
              <option value="youtube">Youtube</option>
            </select>
            <span className="name_input">Tipo de link</span>
          </div>
          <div className="div_input space_div">
            <input
              type="text"
              onChange={(e) => {
                setLinkVideo(e.target.value.replaceAll(" ", ""));
              }}
              value={linkVideo}
            />
            <span className="name_input">Link do vídeo</span>
          </div>
          {linkVideo != "" ? (
            typeVideo === "vimeo" ? (
              <div
                className="div_input"
                onClick={() => {
                  OpenFile(typeVideo, linkVideo);
                }}
              >
                <Svg_Vimeo className="icons" color={colorIcon} />
              </div>
            ) : typeVideo === "youtube" ? (
              <div
                className="div_input"
                onClick={() => {
                  OpenFile(typeVideo, linkVideo);
                }}
              >
                <Svg_Youtube className="icons" color={colorIcon} />
              </div>
            ) : null
          ) : null}
        </div>
        <div className="list_input_data">
          <div className="div_input space_div">
            <input
              type="text"
              onChange={(e) => {
                setWhereBuy(e.target.value.replaceAll(" ", ""));
              }}
              value={whereBuy}
            />
            <span className="name_input">Link de onde comprar</span>
          </div>
        </div>
        <div className="list_input_data">
          <div className="div_input space_div">
            <input
              type="url"
              onChange={(e) => {
                setFilesOfProject(e.target.value);
              }}
              value={filesOfProject}
            />
            <span className="name_input">Arquivos do projeto</span>
          </div>
        </div>
        <div className="list_input_data">
          <div className="div_input space_div">
            <input
              type="url"
              onChange={(e) => {
                setManual(e.target.value);
              }}
              value={manual}
            />
            <span className="name_input">Manual de instruções</span>
          </div>
        </div>
      </div>

      {/* Descrição */}
      <div className="show_page_data">
        <div className="list_type_data">
          <div className="type_title">Descrição</div>
        </div>
        <div className="list_input_data">
          <div className="div_input space_div">
            <input
              type="text"
              className=""
              onChange={(e) => {
                setDescTitle(e.target.value);
              }}
              value={descTitle}
              maxLength={140}
            />
            <span className="name_input">Título</span>
          </div>
        </div>
        <div className="list_input_data">
          <div className="div_input space_div">
            <span className="name_input">Descrição</span>
            <TextareaAutosize
              className="textarea"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              value={description.replace(/<br\s*\/?>/gi, "\n")}
            />
          </div>
        </div>
      </div>

      {/* Categoria */}
      <div className="show_page_data">
        <div className="list_type_data">
          <div className="type_title">Categoria</div>
          <div
            className="btn_save"
            onClick={() => {
              setListCategory([
                ...listCategory,
                { id: 0, id_category: 0, newCategory: "" },
              ]);
            }}
          >
            Adicionar nova categoria
          </div>
        </div>
        <div className="list_input_data">
          {listCategory.length > 0 ? (
            listCategory.map((elem, index) => {
              return (
                <div className="div_data_input" key={index}>
                  <div className="div_input">
                    <select
                      onChange={(e) => {
                        HandleDataCategory(
                          "id_category",
                          index,
                          e.target.value
                        );
                      }}
                      value={elem.id_category}
                      required
                    >
                      <option value="">Selecione uma opção</option>
                      <option value="addNewData">
                        Adicionar nova categoria
                      </option>
                      {showListCategory.map((elem_1, index_1) => {
                        return (
                          <option value={elem_1.id} key={index_1}>
                            {elem_1.name}
                          </option>
                        );
                      })}
                    </select>
                    <span className="name_input">Categoria</span>
                  </div>
                  {elem.id_category === "addNewData" ? (
                    <div className="div_input">
                      <input
                        type="text"
                        className=""
                        onChange={(e) => {
                          HandleDataCategory(
                            "newCategory",
                            index,
                            e.target.value
                          );
                        }}
                        value={elem.newCategory}
                        maxLength={40}
                      />
                      <span className="name_input">Nome da nova categoria</span>
                    </div>
                  ) : null}
                  <div
                    className="div_input"
                    onClick={() => {
                      DeleteCategory(index, elem.id, elem.name);
                    }}
                  >
                    <Svg_Delete className="icons" color="#f00000" />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="no_data">Nenhuma categoria encontrada...</div>
          )}
        </div>
      </div>

      {idPage == 0 ? (
        templateStatus === false ? (
          listTemplate.length > 0 ? (
            <div className="show_page_data">
              <div className="list_type_data">
                <div className="type_title">
                  Deseja utilizar um template que foi registrado no menu
                  "Template de produto"?
                </div>
              </div>
              <div className="list_input_data div_select_opt_template">
                <div
                  className="opt_template yes"
                  onClick={() => {
                    setTemplateSelect("Sim");
                  }}
                >
                  Sim
                </div>
                <div
                  className="opt_template not"
                  onClick={() => {
                    setTemplateStatus(true);
                  }}
                >
                  Não
                </div>
              </div>
              {templateSelect === "Sim" ? (
                <div className="list_input_data">
                  <div className="div_input space_div">
                    <select
                      onChange={(e) => {
                        UseTemplate(e.target.value);
                      }}
                    >
                      <option value="">Selecione uma opção</option>
                      {listTemplate.map((elem, index) => {
                        return (
                          <option value={elem.id} key={index}>
                            {elem.title}
                          </option>
                        );
                      })}
                    </select>
                    <span className="name_input">Escolha um template</span>
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <>
              {ShowDataImg()}
              {ShowDataComponents()}
            </>
          )
        ) : (
          <>
            {ShowDataImg()}
            {ShowDataComponents()}
          </>
        )
      ) : (
        <>
          {ShowDataImg()}
          {ShowDataComponents()}
        </>
      )}
    </form>
  );
}
